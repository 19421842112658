import React from 'react'
import './header.scss'


function Header() {
  return (
    <div className='header'>
        <a href='/' className='logo'>PV</a>
    </div>
  )
}

export default Header