import React from 'react'
import './skills.scss'

function Skills() {
  return (
    <div className='skills'>
      <h1>My Skills</h1>
      <p>During my studies and experiences, I had learned much about web development concepts and technologies. I spent a lot my free time to keep on learning and practicing on various projects.</p>
      <ul>
        <li>React</li>
        <li>Typescript</li>
        <li>HTML & CSS</li>
        <li>SASS</li>
        <li>Git</li>
        <li>Styled Components</li>
      </ul>
    </div>
  )
}

export default Skills