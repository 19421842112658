import React from 'react'
import './footer.scss'


function Footer() {
  return (
    <div className='footer'>
        <a href='/' className='logo'>PV</a>
    </div>
  )
}

export default Footer